import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { modalCloseIcon } from "../../../addressmanagement/src/assets";

interface ConfirmModelProps {
  closeConfirmActionModel: () => void;
  onHandleBtnAction: () => void;
  isOpenConfirmActionModel: boolean;
  modelHeaderText: string;
  modelBodyText: string;
  btnActionText: string;
  btnCancelText: string;
}

const ConfirmActionModel = ({
  closeConfirmActionModel,
  onHandleBtnAction,
  isOpenConfirmActionModel,
  modelHeaderText,
  modelBodyText,
  btnActionText,
  btnCancelText,
}: ConfirmModelProps) => {
  return (
    <Modal
      modalClassName="popopop"
      className="cm-small-modal-4"
      data-testid="closeOneDeleteBtnID"
      isOpen={isOpenConfirmActionModel}
      toggle={closeConfirmActionModel}
      centered={true}
    >
      <ModalHeader
        className="log-out-title-bar  border-0"
        close={
          <img
            data-testid="closeTwoDeleteBtnID"
            src={modalCloseIcon}
            style={{ cursor: "pointer" }}
            alt=""
            onClick={closeConfirmActionModel}
          />
        }
      >
        <span>{modelHeaderText}</span>
      </ModalHeader>
      <ModalBody className="yt-log-body-wrap">
        <div className="text-center log-out-body-text">{modelBodyText}</div>
      </ModalBody>
      <ModalFooter className="log-out-bottom-bar p-1 d-flex justify-content-between">
        <Button
          data-testid="cancelBtnID"
          color="secondary pp-log-out-btn-modal p-3 pp-log-out-btn-light-grey"
          onClick={closeConfirmActionModel}
        >
          {btnCancelText}
        </Button>
        <span className="yt-form-spacer" />
        <Button
          data-testid="deleteAddressBtnID"
          onClick={onHandleBtnAction}
          color="secondary pp-log-out-btn-modal p-3 pp-log-out-btn-dark-grey"
        >
          {btnActionText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmActionModel;
