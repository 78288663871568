import React from "react";
import Skeleton from 'react-loading-skeleton';

const CategorySkeltonLoader = () => (
    <>
        <div className="row">
            <Skeleton containerClassName="skeleton-category-bg"/>
        </div>
    </>
);


export default CategorySkeltonLoader;
