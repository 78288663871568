export const searchIcon = require('./images/search-icon.svg');
export const locationIcon = require('./images/Location.png');
export const logo = require('./images/logo.svg');
export const callIcon = require('./images/call.svg');
export const cartIcon = require('./images/Cart.svg');
export const userIcon = require('./images/user.png');
export const cartItemIcon = require('./images/cartItemIcon.svg');
export const leftArrow = require('./images/leftArrow.svg');
export const HeartImg = require('./images/Shape.png');

//Responive images
export const menuIcon = require('./images/menuicon.png');
export const closeIcon = require('./images/close-icn.png');
export const noSearchIcon = require('./images/no-search-found-icn.png');