// App.js - WEB
import React, { Component } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import HomeScreen from "../../../../components/src/HomeScreen";
// WebRoutes file
import WebRoutes from "./WebRoutes";
import ScrollToTopButton from "react-scroll-to-top";
import { ToastContainer } from "react-toastify";
// @ts-ignore
import { onMessageListener, getTokenData } from './firebaseInit.js';

//css
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-loading-skeleton/dist/skeleton.css'
import "./App.css";
import AppController from "./AppController.web";
//@ts-ignore
import Helmet from "react-helmet";
if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: "HomeScreen"
  };
  const homeScreen = new HomeScreen(defaultProps);
}
class App extends AppController {

  render() {
    const renderHelmet = () => (
      <Helmet>
        {this.state?.title ? <title>{this.state?.title}</title> : <title>E-store Demo</title>}
        <link rel="icon" href={this.state.favicon || '/favicon.ico'} sizes="16x16" />
      </Helmet>
    );
    if (!this.state.isAuthenticated) return null;

    onMessageListener().then((payload: any) => {
    }).catch((error: any) => {});
    return (
      <>
        {renderHelmet()}
        <WebRoutes />
        <ScrollToTopButton smooth />
        <ToastContainer />
      </>
    );
  }
}

export default App;
