import { RouteComponentProps } from "react-router-dom";

import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import _ from "lodash";

const configJSON = require("./config.js");

//content file
import content from "../../../studio-store-restaurant-theme/src/AppWebContent";
import { isEmpty } from "../../../../framework/src/Utilities";
import {
  apiCall,
  errorNotification,
  getLocalState,
  setLocalState,
} from "../Utility.web";

export interface Props extends RouteComponentProps {}
export interface S {
  searchQuery: string;
  headerScrolled: boolean;
  isLoggedIn?: boolean;
  isOpenLocation?: boolean;
  searchDropDown: boolean;
  quickResults?: Array<any>;
  searchStore: string;
  wishlistLength: any;
  userInfoData: any;
  isShowSearchBar: boolean;
  currentPage: number;

  //cart state
  cartItemsLength: any;

  //mobile menu state
  isMobileMenu: boolean;
  mobileMenuItems: Array<any>;
}
export interface SS {}
let isErrorMessageShowOnce = true;
export default class HeaderController extends BlockComponent<Props, S, SS> {
  getLiveSearchApiCallId: string = "";
  getCartApiCallId: string = "";
  getFavoriteListCallId: string = "";
  userProfileGetApiCallId: string = "";
  wrapperRef: any;
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.UpdateWishlist),
      getName(MessageEnum.UpdateCartListApiMessage),
      getName(MessageEnum.UserInfoApiMessage),
      getName(MessageEnum.ShowSearchBarApiMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      searchQuery: "",
      headerScrolled: false,
      isMobileMenu: false,
      searchDropDown: false,
      quickResults: [],
      cartItemsLength: 0,
      wishlistLength: 0,
      userInfoData: {},
      isShowSearchBar: true,
      currentPage: 1,
      mobileMenuItems: [
        { tabTitle: "My Favorites", tabId: "2" },
        { tabTitle: "Reviews", tabId: "3" },
        { tabTitle: "My Orders", tabId: "4" },
        { tabTitle: "Saved Addresses", tabId: "6" },
        { tabTitle: "Help Center", tabId: "7" },
        { tabTitle: "Contact Us", tabId: "8" },
        { tabTitle: "Notifications", tabId: "9" },
        { tabTitle: "Logout", tabId: "10" },
      ],
      searchStore: "",
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    // @ts-ignore
    this.getLiveSearch = _.debounce(this.getLiveSearch, 500);
  }
  // @ts-ignore
  componentDidMount() {
    const userData = getLocalState("userData");
    if (userData) {
      this.setState({
        isLoggedIn: true,
      });
    }
    this.getCartItems();
    this.getFavoriteListItems();
    this.getUserProfile();
    window.addEventListener("scroll", this.listenScrollEvent.bind(this));
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevProps.location.pathname !== this.props.history.location.pathname) {
      const userData = getLocalState("userData");
      if (userData) {
        this.setState({
          isLoggedIn: true,
        });
      }
      this.getCartItems();
      this.getFavoriteListItems();
      this.getUserProfile();
      window.addEventListener("scroll", this.listenScrollEvent.bind(this));
      document.addEventListener("mousedown", this.handleClickOutside);
    }
  }

  // @ts-ignore
  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent.bind(this));
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event: { target: any }) {
    if (
      this.state.searchDropDown &&
      this.state.searchDropDown &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        searchQuery: "",
        searchDropDown: false,
      });
    }
  }

  getDerivedStateFromProps(nextProps: any) {
    if (nextProps) {
      const userData = getLocalState("userData");
      const userCompletedInfo = getLocalState("userCompleteInfo");
      const registartionNotCompleted = getLocalState("registrationDetails");
      const isGuest = getLocalState("guestUserID");
      if (userData && userCompletedInfo) {
        this.setState({
          isLoggedIn: true,
        });
      }
      if (isGuest) {
        this.setState({
          isLoggedIn: false,
        });
      }
      if (isGuest && userCompletedInfo) {
        this.setState({
          isLoggedIn: true,
        });
      }
      if (userData && registartionNotCompleted) {
        this.setState({
          isLoggedIn: false,
        });
      }
    }
  }
  async listenScrollEvent() {
    if (window.scrollY < 73) {
      this.setState({
        headerScrolled: false,
      });
    } else if (window.scrollY > 250) {
      this.setState({
        headerScrolled: true,
      });
    }
  }

  //Mobile Menu
  toogleMobileMenu = () => {
    this.setState({
      isMobileMenu: !this.state.isMobileMenu,
    });
  };

  //check API's Reponses function
  async receive(form: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (message.id === getName(MessageEnum.UpdateWishlist)) {
      const UpdateWishlistLen = message.getData(
        getName(MessageEnum.UpdateWishlistLen)
      );
      this.setState({
        wishlistLength: UpdateWishlistLen,
      });
    }
    if (message.id === getName(MessageEnum.UpdateCartListApiMessage)) {
      const UpdateCartListLen = message.getData(
        getName(MessageEnum.UpdateCartListApiResponseMessage)
      );
      this.setState({
        cartItemsLength: UpdateCartListLen,
      });
    }
    if (message.id === getName(MessageEnum.UserInfoApiMessage)) {
      const userInfoData = message.getData(
        getName(MessageEnum.UserInfoApiResponseMessage)
      );
      if(userInfoData?.name) {
        this.setState({
          userInfoData: userInfoData,
        });
      }
    }
    if (message.id === getName(MessageEnum.ShowSearchBarApiMessage)) {
      const isShowSearchBar = message.getData(
        getName(MessageEnum.ShowSearchBarApiResponseMessage)
      );
      this.setState({
        isShowSearchBar: isShowSearchBar,
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.isValidResponse(responseJSON)) {
        this.apiSuccessCallBacks(apiRequestCallID, responseJSON);
      }

      if (isErrorMessageShowOnce) {
        if(errorMessage){
          errorNotification(errorMessage)
        }
        isErrorMessageShowOnce = false;
      }
    }
  }

  isValidResponse = (responseJson: any) => {
    return responseJson && responseJson.data;
  }

  apiSuccessCallBacks = (apiRequestCallID: any, responseJSON: any) => {
    //get live searches
    if (apiRequestCallID === this.getLiveSearchApiCallId) {
      const { meta } = responseJSON.data;
      this.setState({
        currentPage: meta?.pagination?.next_page,
        quickResults: responseJSON.data?.catalogues,
      });
    }

    //get Cart Items
    if (apiRequestCallID === this.userProfileGetApiCallId) {
      let userInfoData: any;
      if (responseJSON.data?.attributes?.type == "SmsAccount") {
        userInfoData = {
          email:
            responseJSON.data?.attributes?.full_phone_number?.slice(2),
          image: responseJSON.data?.attributes?.image_url,
          name: responseJSON.data?.attributes?.full_name,
        };
      } else {
        userInfoData = {
          email: responseJSON.data?.attributes?.email,
          image: responseJSON.data?.attributes?.image_url,
          name: responseJSON.data?.attributes?.full_name,
        };
      }
      this.setState({ userInfoData: userInfoData });
      let updateUserInfoMessage = new Message(
        getName(MessageEnum.UserInfoApiMessage)
      );
      updateUserInfoMessage.addData(
        getName(MessageEnum.UserInfoApiResponseMessage),
        userInfoData
      );
      runEngine.sendMessage(
        updateUserInfoMessage.id,
        updateUserInfoMessage
      );
    }
    if (apiRequestCallID === this.getCartApiCallId) {
      setLocalState(
        "cartDataLen",
        responseJSON?.data?.order?.data?.attributes?.order_items?.length
      );
      let cartListUpdateMessage = new Message(
        getName(MessageEnum.UpdateCartListApiMessage)
      );
      cartListUpdateMessage.addData(
        getName(MessageEnum.UpdateCartListApiResponseMessage),
        responseJSON?.data?.order?.data?.attributes?.order_items?.length
      );
      runEngine.sendMessage(
        cartListUpdateMessage.id,
        cartListUpdateMessage
      );
    }

    if (apiRequestCallID === this.getFavoriteListCallId) {
      setLocalState(
        "wishlist_len",
        responseJSON?.data?.wishlist?.data?.attributes?.wishlist_items
          ?.length
      );
      var wishlistUpdateMessage = new Message(
        getName(MessageEnum.UpdateWishlist)
      );
      wishlistUpdateMessage.addData(
        getName(MessageEnum.UpdateWishlistLen),
        responseJSON?.data?.wishlist?.data?.attributes?.wishlist_items
          ?.length
      );
      runEngine.sendMessage(
        wishlistUpdateMessage.id,
        wishlistUpdateMessage
      );
    }
  }
  //push recent search param
  pushParams = () => {
    let urlSearch = new URLSearchParams(window.location.search);
    urlSearch.delete("category_id");
    urlSearch.delete("favourite");
    urlSearch.set("q[name]", this.state.searchQuery);
    this.props.history.push(`/?${decodeURIComponent(urlSearch.toString())}`);
  };

  /// get search live
  onHandleManageSearch = (event: any) => {
    this.setState(
      {
        searchDropDown: true,
        searchQuery: !isEmpty(event?.target) ? event.target.value : event,
      },
      this.getLiveSearch
    );
  };

  getLiveSearch: () => Promise<void> = async () => {
    this.getLiveSearchApiCallId = await apiCall({
      contentType: configJSON.filterContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.endPointApiGetLiveSearch}?isWeb=${true}&q[name]=${
        this.state.searchQuery
      }`,
    });
  };

  //get cart info
  getCartItems = async () => {
    this.getCartApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getCartAPIEndPoint,
    });
  };

  getUserProfile = async () => {
    this.userProfileGetApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getUserProfileAPIEndPoint,
    });
  };

  getFavoriteListItems = async () => {
    this.getFavoriteListCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getFavoriteListAPIEndPoint,
    });
  };
}
