import React from "react";
import Skeleton from 'react-loading-skeleton';

const ListSkeletonLoader = () => (
  <>
    <div className="row">
      <div className="col-md-8 col-sm-8 col">
        <Skeleton containerClassName="skeleton-bg" width={154} />
        <Skeleton containerClassName="skeleton-bg" />
        <div className="mb-3">
          <Skeleton containerClassName="skeleton-bg" width={231} />
        </div>
        <Skeleton containerClassName="skeleton-bg" width={231} />

      </div>
      <div className="col-md-4 col-sm-4 col text-end">
        <Skeleton
          containerClassName="skeleton-bg item-category-img"
        />
      </div>
    </div>
  </>
);


export default ListSkeletonLoader;
