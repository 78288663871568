import React from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";

import capitalize from "lodash/capitalize";
import SearchData from "./searchData.web";

//images
import {
  cartIcon,
  logo,
  searchIcon,
  menuIcon,
  userIcon,
  HeartImg,
} from "./assets";
//controller
import HeaderController from "./HeaderController.web";
//content file
import content from "../../../studio-store-restaurant-theme/src/AppWebContent";
//css
import "./css/headerStyle.css";
import { getLocalState } from "../Utility.web";

let MobileMenu: any = withRouter((props: any) => {
  const isMobileMenu = props.isOpen;
  const toogleMenu = props.toogle;
  const isLoggedIn = props.isLoggedIn;
  const isMobileMenuItems = props.isMobileMenuItem;
  return (
    <div
      className={isMobileMenu ? "yt-only-mobile-vw tr" : "yt-only-mobile-vw "}
      style={{ cursor: "pointer" }}
    >
      <div className="yt-mobile-side-nav-wrap">
        <div className="yt-mobile-side-inner-content">
          <div className="yt-inner-cnt-logo">
            <div className="logobox-mb">
              <img
                src={
                  props.themData && props.themData.commonLogoSrc
                    ? props.themData.commonLogoSrc
                    : logo
                }
                className=""
                alt={
                  props.themData && props.themData.commonLogoSrc
                    ? props.themData.ExtraFields?.heading
                    : ""
                }
                onClick={() => {
                  props?.history?.push(`/${props.history?.location?.search}`);
                  toogleMenu();
                }}
                style={{ cursor: "pointer", height: "auto", width: "100%" }}
              />
            </div>
          </div>
          <div className="yt-inner-cnt">
            {!isLoggedIn ? (
              <div
                className="yt-mb-user-profile d-flex"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props?.history?.push("/login");
                  toogleMenu();
                }}
              >
                <div className="yt-mb-nav-login-btn">
                  {content.loginNSignup}
                </div>
              </div>
            ) : (
              <div
                className="yt-mb-user-profile d-flex"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  toogleMenu();
                }}
              >
                <div className="yt-header-user-img" />
                <div
                  className="yt-head-user-det"
                  onClick={() => {
                    props?.history?.push({
                      pathname: "/profile",
                      state: { activeTab: "1" },
                    });
                    toogleMenu();
                  }}
                >
                  <div className="yt-head-name">
                    {getLocalState("userData")?.name}
                  </div>
                  <div className="yt-head-email">
                    {getLocalState("userData")?.email}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="yt-mobile-nav-content">
            {isLoggedIn ? (
              <div
                className="yt-mobile-nav-content"
                style={{ cursor: "default" }}
              >
                <Navbar color="light" light expand="md">
                  <Collapse isOpen={isMobileMenu} navbar>
                    <Nav className="mr-auto" navbar>
                      <NavItem>
                        {isMobileMenuItems.map((elements: any, index: number) => (
                          <NavLink
                            key={index}
                            onClick={() => {
                              props?.history?.push({
                                pathname: "/profile",
                                state: {
                                  activeTab:
                                  elements.tabTitle == "Logout"
                                      ? "logout"
                                      : elements.tabTitle,
                                },
                              });
                              toogleMenu();
                            }}
                          >
                            {elements.tabTitle}
                          </NavLink>
                        ))}
                      </NavItem>
                    </Nav>
                  </Collapse>
                </Navbar>
              </div>
            ) : (
              <div
                className="yt-mobile-nav-content"
                style={{ cursor: "default" }}
              >
                <Navbar color="light" light expand="md">
                  <Collapse isOpen={isMobileMenu} navbar>
                    <Nav className="mr-auto" navbar>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            props?.history.push("/help-center");
                            toogleMenu();
                          }}
                        >
                          {content.helpCenter}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            props?.history.push("/contact-us");
                            toogleMenu();
                          }}
                        >
                          {content.ContactUs}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </Navbar>
              </div>
            )}
          </div>
        </div>
        <div className={"h-100"} onClick={toogleMenu} />
      </div>
    </div>
  );
});

class Header extends HeaderController {
  closeLoactionDrop = () => {
    this.setState({
      isOpenLocation: false,
    });
  };

  render() {
    const appThemeData = getLocalState("appThemData");
    return (
      // @ts-ignore
      <header
        className={
          this.state.headerScrolled
            ? "yt-main-header myHeader2"
            : "yt-main-header myHeader1"
        }
        ref={this.wrapperRef}
      >
        <>
          <div className="logo-container dashboard-carousel-section">
            <Row className="align-items-center">
              <Col className="yt-head-col col-3">
                <div className="d-flex align-items-center">
                  <img
                    src={menuIcon}
                    alt=""
                    className="menuicon d-md-none w3-ripple"
                    onClick={() => {
                      this.toogleMobileMenu();
                    }}
                  />
                  <Link to={`/${this.props.history?.location?.search}`}>
                    <div className="logo-img">
                      <img
                        src={
                          appThemeData?.commonLogoSrc
                            ? appThemeData?.commonLogoSrc
                            : logo
                        }
                        className="logo-img"
                        alt={
                          appThemeData?.ExtraFields?.heading
                            ? capitalize(appThemeData?.ExtraFields?.heading)
                            : ""
                        }
                      />
                    </div>
                  </Link>
                </div>
              </Col>

              <Col className="yt-head-col d-flex justify-content-end align-items-center col-9">
                {this.state.isShowSearchBar && (
                  <div className="yt-head-hide-srch-col mr-24">
                    <div className="searchcontainer header-search-field-wrap">
                      <input
                        type="text"
                        placeholder={"Search"}
                        className="searchinput"
                        value={this.state.searchQuery}
                        onClick={() => this.setState({ searchDropDown: true })}
                        onChange={(event) => {
                          this.onHandleManageSearch(event);
                        }}
                        onKeyUp={(event) => {
                          if (
                            event.key === "Enter" &&
                            this.state.searchQuery != ""
                          ) {
                            this.setState({ searchDropDown: false });
                            setTimeout(() => {
                              this.pushParams();
                            }, 200);
                          }
                        }}
                        onFocus={() => {
                          this.setState({ searchDropDown: false });
                        }}
                      />
                      <img
                        src={searchIcon}
                        alt="search"
                        className="searchicon w3-hover-opacity"
                        onClick={() =>
                          this.state.searchQuery !== "" &&
                          setTimeout(() => {
                            this.setState({ searchDropDown: false });
                            this.pushParams();
                          }, 200)
                        }
                      />
                      {this.state.searchDropDown &&
                        this.state.searchQuery !== "" && (
                          <SearchData
                            hideSearch={() => {
                              this.setState({
                                searchQuery: "",
                                searchDropDown: false,
                              });
                            }}
                            results={this.state.quickResults}
                            isQuickResults={true}
                          />
                        )}
                    </div>
                  </div>
                )}
                <div
                  className="cartcontainer py-2"
                  style={{
                    flexWrap: getLocalState("userData") ? "unset" : "wrap",
                  }}
                >
                  <div
                    onClick={() => {
                      this.setState({
                        searchQuery: "",
                        searchDropDown: false,
                      });
                      this.props?.history.push({
                        pathname: "/wish-list",
                        state: { isWhishList: true },
                      });
                    }}
                  >
                    <span className="d-inline-block yt-cart-icn-mainwp mr-24">
                      {parseInt(this.state.wishlistLength, 10) > 0 && (
                        <span className={"cart-item-dot"}>
                          {parseInt(this.state.wishlistLength, 10) > 9
                            ? "9+"
                            : this.state.wishlistLength}
                        </span>
                      )}
                      <img
                        src={HeartImg}
                        alt="cart"
                        className="carticon w3-ripple"
                      />
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      this.setState({
                        searchQuery: "",
                        searchDropDown: false,
                      });
                      this.props?.history.push("/checkout");
                    }}
                  >
                    <span
                      className={
                        this.state.isLoggedIn
                          ? "mr-24 yt-cart-icn-mainwp"
                          : "d-inline-block yt-cart-icn-mainwp"
                      }
                    >
                      {parseInt(this.state.cartItemsLength, 10) > 0 && (
                        <span className={"cart-item-dot"}>
                          {parseInt(this.state.cartItemsLength, 10) > 9
                            ? "9+"
                            : this.state.cartItemsLength}
                        </span>
                      )}
                      <img
                        src={cartIcon}
                        alt="cart"
                        className="carticon w3-ripple"
                      />
                    </span>
                  </div>
                  {!this.state.isLoggedIn ? (
                    <Button
                      className="loginbutton header-log-btn w3-ripple ml-2"
                      onClick={() => {
                        this.props?.history?.push("/login");
                      }}
                    >
                      <span className="mobileContent">{content.login}</span>
                      <span className="desktopContent">
                        {content.loginNSignup}
                      </span>
                    </Button>
                  ) : (
                    <div
                      className="userbox w3-ripple d-flex align-items-center"
                      onClick={() => {
                        this.setState({
                          searchQuery: "",
                          searchDropDown: false,
                        });
                        this.props?.history.push("/profile");
                      }}
                      title={"Go to Profile"}
                    >
                      <span>
                        <img
                          src={
                            this.state.userInfoData?.image
                              ? this.state.userInfoData?.image
                              : userIcon
                          }
                          alt="user"
                          className="usericon"
                        />
                      </span>
                      <span className="uname">
                        {this.state.userInfoData?.name}
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <div className=" yt-head-mb-search-panel"></div>
          </div>
          <MobileMenu
            themData={appThemeData}
            isOpen={this.state.isMobileMenu}
            toogle={() => this.toogleMobileMenu()}
            isLoggedIn={this.state.isLoggedIn}
            isMobileMenuItem={this.state.mobileMenuItems}
          />
        </>
      </header>
    );
  }
}

export default withRouter(Header);
