import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyD9V6-6-rt72Ik_fSn2ickCc3hiRELHds8",
  authDomain: "react-push-notification-a0d2c.firebaseapp.com",
  projectId: "react-push-notification-a0d2c",
  storageBucket: "react-push-notification-a0d2c.appspot.com",
  messagingSenderId: "339778405792",
  appId: "1:339778405792:web:60f4057a5f32d907d450c5",
  measurementId: "G-XR1ZT5DJH4"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


export const getTokenData = () => {
  return getToken(messaging, { vapidKey: 'BAODoMBnj5eyCrcCcgyWiKD6jWFJqkkcPE205jDfZeykIY9Bxls2eB5ZGgvKIwsVSG1S_xQWrE243adZIB-jyKg' }).then((currentToken) => {
    if (currentToken) {
    
      localStorage.setItem('fcmToken', currentToken.toString())
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});