import React from "react";
import Skeleton from 'react-loading-skeleton';

const CartSkeltonLoader = () => (
    <>
        <div className="row">
            <Skeleton circle containerClassName="skelton-empty-basket" />
            <Skeleton height={20} width={155} containerClassName="mt-3"/>
            <Skeleton height={16} width={255}  containerClassName="mt-3"/>
        </div>
    </>
);


export default CartSkeltonLoader;
