Object.defineProperty(exports, "__esModule", {
  value: true,
});

//content type list
exports.validationApiContentType = "application/json";

/*** Methods List */
exports.apiPostMethod = "POST";
exports.apiPutMethod = "PUT";
exports.apiGetMethod = "GET";
exports.apiDeleteMethod = "DELETE";
exports.apiPatchMethod = "PATCH";

exports.apiMethodTypeGet = "GET";
exports.filterContentType = "application/json";
exports.endPointApiGetLiveSearch = "restaurant/bx_block_filter_items/filtering";
exports.getCartAPIEndPoint = "restaurant/bx_block_order_management/get_cart";
exports.getFavoriteListAPIEndPoint = "restaurant/bx_block_wishlist/wishlists";
exports.getUserProfileAPIEndPoint = "restaurant/bx_block_profile/profile";