import React from "react";
// Customizable Area Start
import EmptyCartContent from "./EmptyCartContent.web";
import { BsX } from "react-icons/bs";
import { FaRegTrashAlt } from "react-icons/fa";
import {
  Button,
  Table,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  FoodTagVeg,
  FoodTagNonVeg,
  modalCloseIcon,
  rewardIcon,
} from "./assets";

import content from "../../studio-store-restaurant-theme/src/AppWebContent";
import AdvBannerSkeltonLoader from "../../studio-store-restaurant-components/src/SkeletonLoader/AdvBannerSkeltonLoader";

import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
// Customizable Area End

import ShoppingCartController, { Props } from "./ShoppingCartController.web";
import {
  errorNotification,
  getLocalState,
  showTwoDecimalDigit,
  warnNotification,
} from "../../studio-store-restaurant-components/src/Utility.web";
import ConfirmActionModel from "../../studio-store-restaurant-components/src/common/ConfirmModel";



const useStyles = {

  ytCartPriceLister: {
    padding: '24px 40px',
    marginBottom: '24px',
    '@media (max-width: 767.92px)': {
      borderRadius: '0',
      padding: '20px 18px',
      marginBottom: '10px',
    },
    '@media (max-width: 1279.92px)': {
      padding: "20px 15px"
    },
    '@media (min-width: 1366px) and (max-width: 1900px)': {
      padding: "24px 40px"
    }
  },

  myOrderCartTitle: {
    padding: '9px 5px',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 'normal' as 'normal',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'left' as 'left',
    color: '#1c213a',
  },

  homePageCartDivider: {
    borderBottom: 'solid 1px #e2e2e2 !important',
    display: 'block',
  },

  cartProdictInfo: {
    width: "100%"
  },

  cartProdictTitle: {
    fontSize: '16px',
    fontWeight: 'normal' as 'normal',
    lineHeight: '24px',
    color: '#000',
    marginBottom: '0',
    maxWidth: '310px',
    textAlign: 'left' as 'left',
    fontStyle: 'normal',
    '@media (max-width: 767.92px)': {
      fontSize: '14px',
      lineHeight: 1.36,
    },
    '@media (max-width: 1279.92px)': {
      fontSize: '16px',
      lineHeight: 1.13,
    },
    '@media (min-width: 1900px)': {
      lineHeight: 1.67,
      maxWidth: '100%',
    }
  },

  flex_2: {
    flex: 2
  },

  foodSubtitle: {
    fontSize: '14px',
    color: '#000000',
    fontWeight: 'normal' as 'normal',
    fontStyle: 'normal' as 'normal',
    lineHeight: '24px',
    margin: '0',
    "::-webkit-scrollbar": {
      width: "2px"
    },
    "::-webkit-scrollbar-track": {
      backgroundColor: '#f1f1f1',
      width: '1px',
    },
    "::-webkit-scrollbar-thumb": {
      background: 'var(--color-gray)',
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: 'var(--color-dark-gray)',

    },
  },

  cartProductAmountQty: {
    textAlign: 'center' as 'center',
    display: 'block',
  },

  removeItemSVG: {
    color: '#FC5185',
    marginRight: '7px',
    fontSize: '30px',
    cursor: 'pointer',
    height: '15px',
    width: '15px',
    "&:hover": {
      transition: 'all 0.5s ease',
      transform: 'rotate(360deg)',
    }
  },

  qtyInput: {
    color: '#000',
    background: '#fff',
    display: 'flex',
    alignItems: 'center' as 'center',
    overflow: 'hidden' as 'hidden',
  },

  qtyCount: {
    background: 'transparent',
    color: 'inherit',
    fontWeight: "normal" as "normal",
    fontSize: 'inherit',
    border: 'none',
    display: 'inline-block',
    minWidth: 0,
    height: '2.5rem',
    lineHeight: 1,
    padding: 0,
    cursor: 'pointer',
    width: '2.5rem',
    textIndent: '-100px',
    overflow: 'hidden' as 'hidden',
    position: 'relative' as 'relative',
    '&:focus': {
      outline: "none",
    },
  },

  qtyCount__minus: {
    height: '30px',
    width: '30px',
    borderRight: '1px solid #e2e2e2',
    background: '#DFDFDF',
    borderRadius: '5px 0px 0px 5px',
    '&:before, &:after': {
      content: "''",
      height: '2px',
      width: '10px',
      position: 'absolute' as 'absolute',
      display: 'block',
      background: '#757575',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      margin: 'auto',
      fontWeight: 400,
      fontSize: '24px',
    },
  },

  qtyCount__add: {
    height: '30px',
    width: '30px',
    borderLeft: '1px solid #e2e2e2',
    background: '#3FC1CB',
    borderRadius: '5px 0px 0px 5px',
    transform: 'rotate(-180deg)',
    '&:before, &:after': {
      content: "''",
      height: '2px',
      width: '10px',
      position: 'absolute' as 'absolute',
      display: 'block',
      background: '#fff',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      margin: 'auto',
    },
  },

  qtyCount__add_after: {
    '&:after': {
      transform: 'rotate(90deg)',
    },
  },

  productQTY: {
    background: 'transparent',
    color: 'inherit',
    fontWeight: "normal" as "normal",
    fontSize: 'inherit',
    minWidth: 0,
    width: '38px',
    height: '30px',
    display: 'inline-block',
    border: '1px solid #DFDFDF',
    textAlign: 'center' as 'center',
    appearance: 'textfield' as 'textfield',
    lineHeight: 1,
    "::-webkit-outer-spin-button": {
      appearance: 'none' as 'none',
      margin: 0,
    },
    "::-webkit-inner-spin-button ": {
      appearance: 'none' as 'none',
      margin: 0,
    },
    '&:focus': {
      outline: "none",
    },
  },

  productCartAmount: {
    width: '100px',
    display: 'flex',
    float: 'right' as 'right',
    justifyContent: 'flex-end',
  },

  cartProductAmountPrice: {
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'right' as 'right',
    color: '#000000',
    display: 'block',
    marginLeft: '4px',
    '@media (max-width: 767.92px)': {
      fontSize: '15px',
      lineHeight: '1.2',
      fontWeight: 'normal' as 'normal',
    },
    '@media (max-width: 1279.92px)': {
      fontSize: '14px',
      fontWeight: 'normal' as 'normal',
      lineHeight: '1.14',
    },
  },

  cartProdictTotalAmount: {
    padding: '0px 0',
    '@media (min-width: 768px)': {
      padding: '0px 0',
    },
    '@media (max-width: 1279.92px)': {
      padding: '0px 0',
    },
  },

  cartProductTAmountTTL: {
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left' as 'left',
    color: '#000000',
    '@media (max-width: 767.92px)': {
      fontSize: '15px',
      fontWeight: 'normal' as 'normal',
      lineHeight: '1.2',
    },
    '@media (max-width: 1279.92px)': {
      fontSize: '14px',
      fontWeight: 'normal' as 'normal',
      lineHeight: '1.14',
    },
  },

  myCartAppliedCode: {
    display: 'flex' as 'flext',
    justifyContent: 'space-between',
  },

  appliedCouponCode: {
    color: '#6cb83a',
    display: 'block',
  },

  myCartEngagements: {
    display: 'flex',
    justifyContent: 'space-between',
  },


  cartCouponCodeMessage_Success: {
    fontSize: '12px',
    lineHeight: '1.46',
    textAlign: 'left' as 'left',
    color: 'var(--color-green)',
  },

  cartCouponCodeMessage_Error: {
    fontSize: '12px',
    lineHeight: '1.01 !important',
    textAlign: 'left' as 'left',
    color: 'var(--color-orange)',
    '@media (max-width: 1279.92px)': {
      fontSize: '12px',
      lineHeight: '1.58',
    },
  },

  availableCoupons: {
    color: '#3b5998',
    display: 'block',
    cursor: 'pointer',
    "&:hover": {
      transition: '0.5s ease',
      textDecoration: 'underline',
    },
  },

  ytCartDisctWrap: {
    padding: '10px 0',
  },

  rewardBalanceSpan: {
    fontSize: '14px',
    lineHeight: '1.43',
    color: 'var(--color-gray)',
    "& label": {
      color: 'var(--color-gold-yellow)',
    },
  },

  formControl: {
    display: 'block' as 'block',
    width: '100%',
    fontWeight: 'normal' as 'normal',
    borderRadius: '6px',
    border: '1px solid #dbdbdb',
    height: 'auto',
    boxShadow: 'none',
    fontSize: '16px',
    lineHeight: '1.13',
    textAlign: 'left' as 'left',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    appearance: 'none' as 'none',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    color: 'var(--color-black)',
    padding: '21px',
    '@media (max-width: 767.92px)': {
      padding: '15px',
      fontSize: '14px',
      fontWeight: 'normal' as 'normal',
      lineHeight: '1.14',
    },
    '@media (max-width: 1279.92px)': {
      padding: '12px 15px',
      fontSize: '14px',
      fontWeight: 'normal' as 'normal',
      lineHeight: '1.14',
    },
  },

  cartCouponBottomWrapper: {
    position: 'absolute' as 'absolute',
    bottom: '-42px',
    left: '0',
    right: '0',
    opacity: '1',
    top: '5rem',
  },

  cartCouponBottomWrapper_succ: {
    opacity: 1
  },

  cartCouponBottomWrapper_err: {
    opacity: 1
  },

  rewardMaxQuantity: {
    color: "grey",
    fontSize: "12px",
  },

  cartCouponChangeBTN: {
    fontSize: '13px',
    fontWeight: 'normal' as 'normal',
    lineHeight: '1.2',
    letterSpacing: 'normal',
    color: 'var(--color-orange)',
    textDecoration: 'underline',
    cursor: 'pointer',
    '@media (max-width: 1279.92px)': {
      fontSize: '14px',
      lineHeight: '1.14',
    },
    "&:hover": {
      textDecoration: 'underline',
    },
  },

  cartCouponBTN: {
    position: 'absolute' as 'absolute',
    top: '50%',
    right: '12px',
    opacity: '0.99',
    borderRadius: '5px',
    backgroundColor: 'var(--button-primary-hover)',
    color: 'var(--button-text-primary-hover)',
    fontSize: '13px',
    fontWeight: 'normal' as 'normal',
    transform: 'translateY(-50%)',
    lineHeight: '1.15',
    padding: '8px 15px',
    "&:hover": {
      transition: '0.5s ease',
      backgroundColor: 'var(--button-primary)',
      color: 'var(--button-text-primary)',
    },
  },

  colorDark: {
    color: 'var(--color-black)',
  },

  cartProdictSubTotalAmount: {
    padding: '0px 0',
    '@media (min-width: 768px)': {
      padding: '0px 0',
    },
    '@media (max-width: 1279.92px)': {
      padding: '0px 0',
    },
  },

  btnProceed: {
    width: 'auto',
    height: '48px',
    margin: 'auto',
  },

  cartProceed: {
    fontSize: '18px',
    fontWeight: 'normal' as 'normal',
    lineHeight: '1.11',
    textAlign: 'center' as 'center',
    backgroundColor: '#364f6b',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    "&:hover": {
      transition: '0.5s ease',
      borderColor: '#364f6b',
      backgroundColor: '#576c85',
      color: '#fff',
    },
  },

  creditDiv: {
    display: "flex"
  },

  mySpin: {
    color: '#7c8188',
    fontSize: '15px',
    "& label": {
      color: '#ffa500',
    }
  },

  adv_Img: {
    width: '100%',
    height: '250px',
    borderRadius: '5px',
    '@media only screen and (min-device-width: 320px) and (max-device-width: 768px)': {
      width: '100%',
      height: 'auto',
      borderRadius: '5px',
    }
  },

  couponTitleBar: {
    borderBottom: '1px solid #dbdbdb !important',
    paddingBottom: '25px',
  },

  couponTitleBar_span: {
    position: 'absolute' as 'absolute',
    top: '27px',
    left: '0',
    right: '0',
    textAlign: 'center' as 'center',
    margin: '0',
    fontSize: '18px',
    fontWeight: 'normal' as 'normal',
    lineHeight: '1.11',
    color: 'var(--color-black)',
    '@media (max-width: 767.92px)': {
      fontSize: '18px',
      fontWeight: "normal" as "normal",
      lineHeight: '1.11',
    },
    '@media (max-width: 1279.92px)': {
      fontSize: '18px',
      fontWeight: "normal" as "normal",
      lineHeight: '1.11',
    },
  },

  ytCouponWrap: {
    padding: '30px 15px',
  },

  couponBodytext: {
    fontSize: '16px',
    lineHeight: '1.13',
    color: 'var(--color-dark-grey)',
    WebkitUserSelect: 'none' as 'none',
    MozUserSelect: 'none' as 'none',
    userSelect: 'none' as 'none',
    '&:not(:first-child)': {
      paddingTop: '18px'
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid #dbdbdb',
      paddingBottom: '18px',
    },
    "& p": {
      color: '#264653',
      fontSize: '18px',
      fontWeight: 'normal' as 'normal',
      '& .active': {
        color: 'green',
      }
    },
    "& p svg": {
      color: '#264653',
      fontSize: '18px',
      fontWeight: 'normal' as 'normal',
      '& .active': {
        color: 'green',
      }
    },
  },

}

class ShoppingCart extends ShoppingCartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <>
        {this.props?.myCartList?.order &&
          this.props?.myCartList?.order?.data?.attributes?.order_items &&
          this.props?.myCartList?.order?.data?.attributes?.order_items.length >
          0 ? (
          <div>
            <div className={`${classes.ytCartPriceLister} bg-white `}>
              <div className={classes.myOrderCartTitle}>{content.yourCart}</div>
              <span className={`${classes.homePageCartDivider} mb-4 `} />
              {this.props?.myCartList?.order?.data?.attributes?.order_items.map(
                (inCart, index) => (
                  <div className="row mb-3" key={index}>
                    <div className={`${classes.flex_2} col `}>
                      <div className={classes.cartProdictInfo}>
                        <h2 className={`${classes.cartProdictTitle} text-capitalize `}>
                          {inCart?.attributes?.catalogue?.attributes?.name?.toLowerCase()}
                        </h2>
                        <p
                          key={index}
                          className={classes.foodSubtitle}
                          style={{ color: "#757575" }}
                        >
                          <span>
                            {" "}
                            {this.onHandleCustomizeItems(
                              inCart?.attributes?.order_item_options
                            )}{" "}
                          </span>
                        </p>
                      </div>
                      <div className="d-flex">
                        <div className="veg-nonVeg">
                          <img
                            src={
                              inCart?.attributes?.catalogue?.attributes?.non_veg
                                ? FoodTagNonVeg
                                : FoodTagVeg
                            }
                            className="img-fluid"
                          />
                          {inCart?.attributes?.catalogue?.attributes
                            ?.non_veg ? (
                            <span>&nbsp;&nbsp;Non-vegetarian</span>
                          ) : (
                            <span>&nbsp;&nbsp;Vegetarian</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <span className={`${classes.cartProductAmountQty} d-flex justify-content-end align-items-center `}>
                        <FaRegTrashAlt
                          data-testid="rmItemSvgID"
                          className={classes.removeItemSVG}
                          onClick={() => this.removeCartItem(inCart?.id)}
                        />
                        <div className={classes.qtyInput}>
                          <button
                            data-testid="subtractCartQuantityID"
                            className={`${classes.qtyCount} ${classes.qtyCount__minus}`}
                            data-action="minus"
                            type="button"
                            onClick={() => {
                              if (
                                Number(inCart?.attributes?.catalogue?.attributes
                                  ?.cart_quantity) +
                                this.state.itemQuantity -
                                1 <
                                1
                              ) {
                                this.setState({
                                  isCartItemRemove: true,
                                  cartItemId: inCart?.id,
                                });
                              } else {
                                this.updateCartQuantity(inCart, "Subtract");
                              }
                            }}
                          >
                            -
                          </button>
                          <input
                            className={classes.productQTY}
                            type="number"
                            name="product-qty"
                            min="0"
                            max="10"
                            value={inCart?.attributes?.quantity}
                          />
                          <button
                            data-testid="addCartQuantityID"
                            className={`${classes.qtyCount} ${classes.qtyCount__add} ${classes.qtyCount__add_after}`}
                            data-action="add"
                            type="button"
                            onClick={() => {
                              let cartLimit =
                                inCart?.attributes?.catalogue?.attributes
                                  ?.cart_limit;
                              if (
                                Number(inCart?.attributes?.catalogue?.attributes
                                  ?.cart_quantity) +
                                this.state.itemQuantity +
                                1 >
                                Number(cartLimit) &&
                                cartLimit
                              ) {
                                warnNotification(
                                  `You can not add more than ${cartLimit} quantity of this product`
                                );
                              } else {
                                this.updateCartQuantity(inCart, "Add");
                              }
                            }}
                          >
                            +
                          </button>
                        </div>
                      </span>
                    </div>
                    <div className="col text-end">
                      <div className={classes.productCartAmount}>
                        <span
                          style={{ marginTop: 4 }}
                          className={classes.cartProductAmountPrice}
                        >
                          {showTwoDecimalDigit(inCart?.attributes?.total_price)}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              )}

              <span className={`${classes.homePageCartDivider} mt-3`} />
              <Table borderless className={`${classes.cartProdictTotalAmount} mb-0 `} >
                <tbody>
                  <>
                    {parseFloat(
                      this.props?.myCartList?.order?.data?.attributes?.sub_total
                    ) > 0 && (
                        <tr>
                          <td>
                            <span
                              className={classes.cartProductTAmountTTL}
                              style={{ fontWeight: "bold" }}
                            >
                              {content.subTotal}
                            </span>
                          </td>
                          <td>
                            <span
                              className={classes.cartProductAmountPrice}
                              style={{ fontWeight: "bold" }}
                            >
                              {showTwoDecimalDigit(
                                this.props?.myCartList?.order?.data?.attributes
                                  ?.sub_total
                              )}
                            </span>
                          </td>
                        </tr>
                      )}
                    {this.props?.myCartList?.order?.data?.attributes
                      ?.total_tax > 0 && (
                        <tr>
                          <td>
                            <span
                              className={classes.cartProductTAmountTTL}
                              style={{ fontSize: "14px" }}
                            >
                              {content.taxes}
                            </span>
                          </td>
                          <td>
                            <span
                              className={classes.cartProductAmountPrice}
                              style={{ fontSize: "14px" }}
                            >
                              +
                              {showTwoDecimalDigit(
                                this.props?.myCartList?.order?.data?.attributes
                                  ?.total_tax
                              )}
                            </span>
                          </td>
                        </tr>
                      )}
                    {parseFloat(
                      this.props?.myCartList?.order?.data?.attributes
                        ?.shipping_charge
                    ) > 0 && (
                        <tr>
                          <td>
                            <span
                              className={classes.cartProductTAmountTTL}
                              style={{ fontSize: "14px" }}
                            >
                              {content.deliveryCharges}
                            </span>
                          </td>
                          <td>
                            <span
                              className={classes.cartProductAmountPrice}
                              style={{ fontSize: "14px" }}
                            >
                              +
                              {showTwoDecimalDigit(
                                this.props?.myCartList?.order?.data?.attributes
                                  ?.shipping_charge
                              )}
                            </span>
                          </td>
                        </tr>
                      )}
                    {parseFloat(
                      this.props?.myCartList?.order?.data?.attributes
                        ?.shipping_discount
                    ) > 0 && (
                        <tr>
                          <td>
                            <span
                              className={classes.cartProductTAmountTTL}
                              style={{ fontSize: "14px" }}
                            >
                              {content.deliveryDiscount}
                            </span>
                          </td>
                          <td>
                            <span
                              className={classes.cartProductAmountPrice}
                              style={{ fontSize: "14px" }}
                            >
                              -
                              {showTwoDecimalDigit(
                                this.props?.myCartList?.order?.data?.attributes
                                  ?.shipping_discount
                              )}
                            </span>
                          </td>
                        </tr>
                      )}
                  </>

                </tbody>
              </Table>
              <div>
                <Table
                  className={`${classes.cartProdictTotalAmount} mt-2 mb-0 `}
                  borderless
                >
                  <tbody>
                    {this.props?.myCartList?.order?.data?.attributes
                      ?.total_discount > 0 && (
                        <tr>
                          <td>
                            <span
                              className={classes.cartProductTAmountTTL}
                              style={{ fontSize: "14px" }}
                            >
                              {content.discount}
                            </span>
                          </td>
                          <td>
                            <span
                              className={classes.cartProductAmountPrice}
                              style={{ fontSize: "14px" }}
                            >
                              -
                              {showTwoDecimalDigit(
                                this.props?.myCartList?.order?.data?.attributes
                                  ?.total_discount
                              )}
                            </span>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </Table>
              </div>

              <span className={classes.homePageCartDivider} />
              <div className="cart-coupon mt-3">
                {this.state.toggleRewards &&
                  this.props?.myCartList?.order?.data?.attributes
                    ?.coupon_code_id !== null && (
                    <>
                      <div className={classes.myCartAppliedCode}>
                        <span
                          data-testid="toggleCoupenModalID"
                          className={classes.appliedCouponCode}
                          onClick={() => this.toggleCouponModal()}
                        >
                          {content.appliedCoupon}
                        </span>

                        <span
                          data-testid="toggleRewardsModalID"
                          className={classes.appliedCouponCode}
                          onClick={() => this.openReward()}
                        >
                          {
                            this.couponCode(this.props?.myCartList)
                          }
                          <BsX
                            data-testid="rmRewardDataID"
                            onClick={() =>
                              this.removeCoupon(
                                this.props?.myCartList?.order?.data
                              )
                            }
                            className={classes.removeItemSVG}
                          />
                        </span>
                      </div>
                      <span className={classes.homePageCartDivider} />
                    </>
                  )}
                {this.state.toggleRewards &&
                  this.props?.myCartList?.order?.data?.attributes
                    ?.reward_history_id !== null && (
                    <>
                      <div className={classes.myCartAppliedCode}>
                        <span
                          className={classes.appliedCouponCode}
                          data-testid="toggleCoupensModalID"
                          onClick={() => this.toggleCouponModal()}
                        >
                          {content.appliedReward}
                        </span>
                        <span
                          className={classes.appliedCouponCode}
                          data-testid="toggleRewardsModalsID"
                          onClick={() => this.openReward()}
                        >
                          {
                            this.props?.myCartList?.order?.data?.attributes
                              ?.used_reward_balance
                          }
                          <BsX
                            data-testid="rmRewardsDataID"
                            onClick={() => {
                              this.removeRewards(
                                this.props?.myCartList?.order?.data
                              );
                            }}
                          />
                        </span>
                      </div>
                      <span className={classes.homePageCartDivider} />
                    </>
                  )}
                <div className={`${classes.myCartEngagements} mb-2`}>
                  <>
                    {!this.state.toggleRewards && (
                      <>
                        <span
                          data-testid="toggleCoupenModalID"
                          className={classes.availableCoupons}
                          onClick={() => this.toggleCouponModal()}
                        >
                          {content.viewAvailableCoupons}
                        </span>

                        <span
                          data-testid="toggleRewardsModalID"
                          className={classes.availableCoupons}
                          onClick={() => this.openReward()}
                        >
                          {content.applyRewards}
                        </span>
                      </>
                    )}
                    {this.isNotCouponCode(this.props?.myCartList) == true && (
                      <>
                        <span
                          className={classes.availableCoupons}
                          data-testid="toggleCoupensID"
                          onClick={() => this.toggleCouponModal()}
                        >
                          {content.viewAvailableCoupons}
                        </span>

                        <span
                          className={classes.availableCoupons}
                          data-testid="toggleRewardCoupensID"
                          onClick={() => this.closeReward()}
                        >
                          {content.applyCoupons}
                        </span>
                      </>
                    )}
                  </>
                </div>
                {this.state.toggleRewards ? (
                  <Form className={`${classes.ytCartDisctWrap} pb-4`}>
                    <span className={classes.rewardBalanceSpan}>
                      You have{" "}
                      <label>
                        <img src={rewardIcon} alt="" />
                        {
                          this.props?.myCartList?.order?.data?.attributes
                            ?.current_reward_balance
                        }{" "}
                        Reward points Left.
                      </label>{" "}
                      10 For every
                      <span>
                        {" "}
                        {String(
                          this.props?.myCartList?.order?.data?.attributes
                            ?.reward_setting?.exchange_rate * 10
                        )}
                      </span>
                    </span>

                    <FormGroup
                      className="m-0 yt-form-cpn-err error"
                    //  yt-form-cpn-err success 
                    >
                      <input
                        type="number"
                        name="rewards"
                        data-testid="applyRewardsID"
                        className={classes.formControl}
                        id=""
                        placeholder="Apply Rewards"
                        value={this.state.rewardsQuantity || ""}
                        onChange={(event) => { this.rewardsQuantity(event, this.props.myCartList) }}
                        disabled={
                          !this.state.isRewardEnable &&
                          this.props?.myCartList?.order?.data?.attributes
                            ?.reward_history_id !== null
                        }
                        maxLength={15}
                      />
                      <div className={`${classes.cartCouponBottomWrapper} pb-3 d-flex align-items-center justify-content-between`}>
                        <>
                          {this.isCouponCode(this.props.myCartList) && (
                            <span
                              className={classes.cartCouponCodeMessage_Success}
                              style={{ color: "#6cb83a", display: "block" }}
                            >
                              You saved
                              {showTwoDecimalDigit(
                                this.props?.myCartList?.order?.data
                                  ?.attributes?.applied_reward_discount
                              )}{" "}
                            </span>
                          )}
                          <span className={classes.rewardMaxQuantity}>
                            Max Limit{" "}
                            {
                              this.props?.myCartList?.order?.data?.attributes
                                ?.reward_setting?.max_reward_usage_limit
                            }{" "}
                            Points at a time
                          </span>
                          {this.props?.myCartList?.order?.data?.attributes
                            ?.reward_history_id && (
                              <Button
                                color=""
                                className={`${classes.cartCouponChangeBTN} p-0`}
                                data-testid="removeRewardsID"
                                onClick={() =>
                                  this.removeRewards(
                                    this.props?.myCartList?.order?.data
                                  )
                                }
                              >
                                Remove Rewards
                              </Button>
                            )}
                        </>
                      </div>
                      {this.state.codeErrorContent && (
                        <span className={classes.cartCouponCodeMessage_Error}
                        >
                          {this.state.codeErrorContent}
                        </span>
                      )}
                      {parseInt(
                        this.props?.myCartList?.order?.data?.attributes
                          ?.applied_reward_discount,
                        10
                      ) > 0 && !this.state.isRewardEnable ? (
                        <Button
                          color=""
                          className={classes.cartCouponBTN}
                          data-testid="changeReward"
                          onClick={() => {
                            this.setState({
                              isRewardEnable: true,
                            });
                          }}
                        >
                          Change
                        </Button>
                      ) : (
                        <Button
                          data-testid="applyRewardsBtnID"
                          color=""
                          className={classes.cartCouponBTN}
                          onClick={() =>
                            this.applyReward()
                          }
                        >
                          Apply
                        </Button>
                      )}
                    </FormGroup>
                  </Form>
                ) : (
                  <Form className=" pb-3">
                    <FormGroup className="m-0 ">
                      <input
                        data-testid="applyCoupenCodeID"
                        name="copenCode"
                        type="text"
                        className={classes.formControl}
                        id=""
                        placeholder="Apply Coupon"
                        value={this.state.couponCode}
                        onChange={(event) => this.changeCouponCode(event.target.value)}
                        disabled={
                          !this.state.isCouponEnable &&
                          this.props?.myCartList?.order?.data?.attributes
                            ?.coupon_code_id !== null
                        }
                        maxLength={15}
                      />
                      <div className={`${classes.cartCouponBottomWrapper} pb-3 d-flex align-items-center justify-content-between`}>
                        {this.props?.myCartList?.order?.data?.attributes
                          ?.coupon_code_id !== null &&
                          !this.state.codeErrorContent && (
                            <>
                              <span
                                className={classes.cartCouponCodeMessage_Success}
                                style={{ color: "#6cb83a", display: "block" }}
                              >
                                You saved
                                {showTwoDecimalDigit(
                                  this.props?.myCartList?.order?.data
                                    ?.attributes?.applied_discount
                                )}
                              </span>
                              <Button
                                color=""
                                className={`${classes.cartCouponChangeBTN} p-0`}
                                data-testid="removeCoupenBtnID"
                                onClick={() =>
                                  this.removeCoupon(
                                    this.props?.myCartList?.order?.data
                                  )
                                }
                              >
                                Remove Coupon
                              </Button>
                            </>
                          )}

                        {this.state.codeEmpty && (
                          <span className={classes.cartCouponCodeMessage_Error}>
                            {this.state.codeEmpty &&
                              "Coupon code can't be empty"}
                          </span>
                        )}
                      </div>
                      {!this.state.isCouponEnable &&
                        this.props?.myCartList?.order?.data?.attributes
                          ?.coupon_code_id !== null ? (
                        <Button
                          color=""
                          className={classes.cartCouponBTN}
                          onClick={() => this.isCouponEnable()}
                        >
                          Change
                        </Button>
                      ) : (
                        <Button
                          data-testid="applyCouponBtnID"
                          color=""
                          className={classes.cartCouponBTN}
                          onClick={() =>
                            this.applyCoupon(
                              this.props?.myCartList?.order?.data
                            )
                          }
                        >
                          Apply
                        </Button>
                      )}
                    </FormGroup>
                  </Form>
                )}
              </div>
              <Table className={`${classes.cartProdictSubTotalAmount} mb-0`} borderless>
                <tbody>
                  <tr className="mb-0">
                    <td>
                      <span
                        className={`${classes.cartProductTAmountTTL} ${classes.colorDark}`}
                        style={{ fontWeight: "bold" }}
                      >
                        Total Amount
                      </span>
                    </td>
                    <td>
                      <span
                        className={`${classes.cartProductAmountPrice} ${classes.colorDark}`}
                        style={{ fontWeight: "bold" }}
                      >
                        {showTwoDecimalDigit(
                          this.props?.myCartList?.order?.data?.attributes?.total
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className={classes.btnProceed}>
                <Button
                  data-testid="redirectBtnID"
                  className={`${classes.cartProceed} py-3 px-5`}
                  onClick={() => {
                    if (this.state.isLoggedIn || this.state.isActivated) {
                      if (
                        getLocalState("commonSettings")?.min_cart_val &&
                        this.props?.myCartList?.order?.data?.attributes
                          ?.sub_total <
                        getLocalState("commonSettings")?.min_cart_val
                      ) {
                        errorNotification(
                          `Minimum Cart Value should be ${getLocalState("commonSettings")?.min_cart_val
                          }`
                        );
                      } else {
                        this.props.history.push("/checkout");
                      }
                    } else {
                      this.props.history.push("/login");
                    }
                  }}
                >
                  Proceed to Checkout
                </Button>
              </div>
            </div>
            <div className={classes.creditDiv}>
              <span className={classes.mySpin}>
                You can earn{" "}
                <label>
                  <img src={rewardIcon} alt="" />
                  {
                    this.props?.myCartList?.order?.data?.attributes
                      ?.earn_reward_point
                  }{" "}
                  reward points
                </label>{" "}
                for completing your order!
              </span>
            </div>
          </div>
        ) : (
          <EmptyCartContent />
        )}
        {this.state.bannerImgList?.length > 0
          ? this.state.bannerImgList?.map((banner, index) => (
            <div className="mt-4 mb-40 text-center" key={index}>
              <img src={banner?.url} alt="" className={`${classes.adv_Img} img-fluid`} />
            </div>
          ))
          : new Array(2).fill(0).map((_, index) => (
            <div className="mt-4 h-25" key={index}>
              <AdvBannerSkeltonLoader />
            </div>
          ))}

        <Modal
          data-testid="openModalCoupen"
          isOpen={this.state.showAllCoupons}
          toggle={() => this.toggleCouponModal()}
          className=""
          centered={true}
          modalClassName="popopop"
        >
          <ModalHeader
            className={`${classes.couponTitleBar} border-0`}
            close={
              <img
                alt="close-btn"
                className="toggle-Modal"
                src={modalCloseIcon}
                onClick={() => this.toggleCouponModal()}
              />
            }
          >
            <span className={classes.couponTitleBar_span}>{content.viewAvailableCoupons}</span>
          </ModalHeader>
          <ModalBody className={classes.ytCouponWrap}>
            {this.state.allCoupons.length > 0 ? (
              <>
                {this.state.allCoupons?.map((coupon, index) => (
                  <div
                    className={classes.couponBodytext}
                    data-testid={`coupon-${index}`}
                    onClick={() => this.copyCoupon(coupon, index)}
                    key={index}
                  >
                    <div className="row position-relative mb-3">
                      <div className="col-md-9 col-sm-9">
                        <p
                          className={`${this.state.couponCopied === index ? "active" : null
                            }`}
                        >
                          {coupon?.attributes?.code}{" "}
                          {this.state.couponCopied === index
                            ? "Code Copied!"
                            : null}
                        </p>
                      </div>
                      <div className="col-md-3 col-sm-3 text-end">
                        <Button className={`${classes.cartCouponBTN} btn`}>Apply</Button>
                      </div>
                    </div>
                    <span>
                      Get{" "}
                      {coupon?.attributes?.discount_type === "flat"
                        ? `${showTwoDecimalDigit(coupon?.attributes?.discount)}`
                        : `${showTwoDecimalDigit(
                          coupon?.attributes?.discount
                        )}%`}{" "}
                      <span>Off on order of minimum </span>
                      {showTwoDecimalDigit(
                        coupon?.attributes?.min_cart_value
                      )}{" "}
                      <span>to </span>
                      {showTwoDecimalDigit(coupon?.attributes?.max_cart_value)}.
                    </span>
                  </div>
                ))}
              </>
            ) : (
              <div className="coupon-body-text noCoupon">
                <p>{content.noCouponsAvailable}</p>
              </div>
            )}
          </ModalBody>
        </Modal>

       {this.state.isCartItemRemove && (
          <ConfirmActionModel
            data-testid="removeProduct"
            closeConfirmActionModel={this.removeProductModalClose}
            onHandleBtnAction={this.removeProductFromCart}
            isOpenConfirmActionModel={this.state.isCartItemRemove}
            modelHeaderText={content.removeProduct}
            modelBodyText={content.removeProductTagline}
            btnActionText={content.yesDelete}
            btnCancelText={content.cancelBtn}
          />
        )}
      </>
    );
    // Customizable Area End
  }
}

export default withRouter(withStyles(useStyles)(ShoppingCart));
export { ShoppingCart };
// Customizable Area Start
// Customizable Area End
