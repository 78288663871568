import { RouteComponentProps } from "react-router-dom";

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import chunk from "lodash/chunk";
import { apiCall, setLocalState } from "../Utility.web";
const configJSON = require("./config.js");
//content file
import MessageConstants, {
  getMessageName
} from "../MessageConstants";
export interface Props extends RouteComponentProps {}
export interface S {
  isShowFB: string;
  isShowInsta: string;
  isShowTwitter: string;
  isShowGoogle: string;
  contactInfo: any;
  theamData?: any;
  usefulLinks: any;
  loading: boolean;
  isShowYoutube: string;
}
export interface SS {}

export default class FooterController extends BlockComponent<Props, S, SS> {
  getFAQAPICallId: string = "";
  getHelpCenterAPICallId: string = "";
  getBrandSettingAPICallId: string = "";
  getCommonSettingAPICallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      isShowFB: "",
      isShowInsta: "",
      isShowTwitter: "",
      isShowYoutube: "",
      isShowGoogle: "",
      loading: false,
      usefulLinks: [],
      contactInfo: "",
    };
  }
  routeHelpCenter = (value: any) => {
    if (value !== undefined) {
      let path = "/help-center/" + value;
      setTimeout(() => {
        //@ts-ignore
        this.props.history.push(path);
      }, 1000);
    } else {
      let path = "/help-center";
      this.props?.history?.push(path);
    }
  };

  async componentDidMount() {
    this.getDerivedStateFromProps();
  }

  getDerivedStateFromProps() {
    if (window.localStorage.length === 0) {
      this.getBrandSettings();
      this.getCommonSettings();
    }
    this.getHelpCenter();
    this.getFaqs();
    this.getBrandSettings();
    this.getCommonSettings();
  }

  //check API's Reponses function
  async receive(form: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(this.isValidResponse(responseJSON)){
        this.apiSuccessCallBacks(apiRequestCallID, responseJSON);
      }
      
      
      if (apiRequestCallID === this.getBrandSettingAPICallId) {
        this.apiBrandSettingData(responseJSON);
        
      }
    }
  }

  apiBrandSettingData = (responseJSON: any) => {
    if (responseJSON && responseJSON?.brand_setting) {
      const { data } = responseJSON?.brand_setting;
      this.setState({
        isShowFB: data?.attributes?.footerContent?.facebookSrc
          ? data?.attributes?.footerContent?.facebookSrc
          : "",
        isShowInsta: data?.attributes?.footerContent?.instagramSrc
          ? data?.attributes?.footerContent?.instagramSrc
          : "",
        isShowTwitter: data?.attributes?.footerContent?.twitterSrc
          ? data?.attributes?.footerContent?.twitterSrc
          : "",
        isShowYoutube: data?.attributes?.footerContent?.youtubeSrc
          ? data?.attributes?.footerContent?.youtubeSrc
          : "",
        theamData: data?.attributes,
        contactInfo: data?.attributes?.commonTextsContent,
      });

      const title = data?.attributes?.ExtraFields?.heading;
      const faviconMessage = new Message(
        getMessageName(MessageConstants.updateFaviconAndTitle),
      );
      window.localStorage.setItem("favicon-title", JSON.stringify({ title }))
      faviconMessage.addData(
        getMessageName(MessageConstants.updateFaviconAndTitleData),
        {
          title
        }
      ),
      runEngine.sendMessage(faviconMessage.id, faviconMessage);
      let CountryInfo = {
        name: data?.attributes?.ExtraFields?.country,
        currency_type: data?.attributes?.ExtraFields?.currency_type,
        country_code: data?.attributes?.ExtraFields?.country_code,
      };
      setLocalState("country_info", JSON.stringify(CountryInfo));
      this.getMyThemes(data?.attributes);
    }
  }
  isValidResponse = (responseJson: any) => {
    return responseJson && responseJson.data;
  }
  
  apiSuccessCallBacks = (apiRequestCallID: any, responseJSON: any) => {

    if (apiRequestCallID === this.getHelpCenterAPICallId) {
        this.setState({
          loading: false,
          usefulLinks:
            responseJSON.data.length > 4
              ? chunk(responseJSON.data, 5)
              : chunk(responseJSON.data, 4),
        });
    }
    if (apiRequestCallID === this.getFAQAPICallId) {
      if (
        responseJSON.data.faqs &&
        responseJSON.data.faqs.length > 0
      ) {
        this.setState({
          loading: false,
        });
        const copyHelpCenterData = [...this.state.usefulLinks];
        const isFAQID = copyHelpCenterData.length + 1;
        const smapleFAQ = {
          id: isFAQID.toString(),
          type: "help_center",
          attributes: {
            help_center_type: "faq's",
            title: "FAQ's",
            description: responseJSON.data.faqs,
          },
        };
        copyHelpCenterData.push([smapleFAQ]);
        this.setState({
          usefulLinks: copyHelpCenterData,
          loading: false,
        });
      }
    }
    
    if (apiRequestCallID === this.getCommonSettingAPICallId) {
      let commonSettings = {
        ...responseJSON.data,
        ...responseJSON.data.settings,
      };
      delete commonSettings.settings;
      setLocalState("commonSettings", JSON.stringify(commonSettings));
    }
  }

  //GET FAQ's API call
  getFaqs = () => {
    this.setState({
      loading: true,
    });
    setTimeout(async () => {
      this.getFAQAPICallId = await apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.apiGetMethod,
        endPoint: configJSON.getFAQAPIEndPoint,
      });
    }, 2000);
  };

  //GET HelpCenter API Call
  getHelpCenter = async () => {
    this.setState({
      loading: true,
    });

    this.getHelpCenterAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getHelpCenterAPIEndPoint,
    });
  };

  //get BrandSetting
  getBrandSettings = async () => {
    this.setState({
      loading: true,
    });

    this.getBrandSettingAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getBrandSettingsAPIEndPoint,
    });
  };
  //get common settings
  getCommonSettings = async () => {
    this.setState({
      loading: true,
    });

    this.getCommonSettingAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getCommonSettingsAPIEndPoint,
    });
  };

  // Brand Settings Start
  //@ts-ignore
  getMyThemes = (themeAttributes): any => {
    if (themeAttributes) {
      setLocalState("appThemData", JSON.stringify(themeAttributes));
      let CountryInfo = {
        name: themeAttributes?.ExtraFields?.country,
        currency_type: themeAttributes?.ExtraFields?.currency_type,
        country_code: themeAttributes?.ExtraFields?.country_code,
      };
      setLocalState("country_info", JSON.stringify(CountryInfo));
      const root = document.documentElement;
      root.style.setProperty(
        "--button-primary",
        themeAttributes?.buttonsColor?.regularButtonColor
      );
      root.style.setProperty(
        "--button-text-primary",
        themeAttributes?.buttonsColor?.regularTextColor
      );
      root.style.setProperty(
        "--button-primary-hover",
        themeAttributes?.buttonsColor?.hoverButtonColor
      );
      root.style.setProperty(
        "--button-text-primary-hover",
        themeAttributes?.buttonsColor?.hoverTextColor
      );
      root.style.setProperty(
        "--color-RegularActiveText",
        themeAttributes?.mainTextsColor?.activeColorCode
      );
    }
  };
  // Brand Settings End
}
