//@ts-ignore
import React from "react";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { AiOutlineYoutube } from "react-icons/ai";
//images
import {
  AppStoreImg,
  facebookIcon,
  googleIcon,
  googlePlayStoreImg,
  instaIcon,
  twitterIcon,
} from "./assets";
//css
import "./css/footerStyle.css";
//controller
import FooterController from "./FooterController.web";

//content file
import content from "../../../studio-store-restaurant-theme/src/AppWebContent";
import { getLocalState } from "../Utility.web";

class Footer extends FooterController {
  render() {
    return (
      <>
        <footer
          style={{
            borderTop: "1px solid rgb(223, 223, 223)",
            background: "rgb(248,249,250)",
          }}
          className="mt-100"
        >
          <div className="footercontainer yt-main-footer  dashboard-carousel-section">
            <>
              <Row className="mt-5">
                <Col className="col-md-3 col-sm-3 col text-center">
                  <div className="yt-footer-widget-title">
                    {content.ContactUs}
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    onClick={() => {
                      this.props.history.push("/contact-us");
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div className=" yt-text-about">Send a message</div>
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    onClick={() => {
                      this.props.history.push("/contact-us");
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div className=" yt-text-about">
                      +{getLocalState("country_info")?.country_code}{" "}
                      {this.state.contactInfo?.callUs}
                    </div>
                  </div>
                </Col>

                <Col className="col-md-3 col-sm-3 col text-center">
                  <div className="yt-footer-widget-title">
                    {content.aboutUs}
                  </div>
                  <div className="d-flex justify-content-center">
                    <div
                      className=" yt-text-about"
                      onClick={() => {
                        this.props.history.push("/review");
                        window.scrollTo(0, 0);
                      }}
                    >
                      Customer reviews
                    </div>
                  </div>
                </Col>

                <Col className="col-md-3 col-sm-3 col text-center">
                  <div className="yt-footer-widget-title">Social</div>
                  <div className="d-flex justify-content-center">
                    <div className="social_all">
                      {this.state.isShowFB && (
                        <>
                          <a
                            href={
                              this.state.isShowFB
                                ? this.state.isShowFB
                                : "https:www.facebook.com/"
                            }
                            target="_blank"
                          >
                            <img
                              src={facebookIcon}
                              className="social_logos w3-ripple"
                              alt="social"
                            />
                          </a>{" "}
                        </>
                      )}
                      {this.state.isShowGoogle && (
                        <>
                          <a
                            href={
                              this.state.isShowGoogle
                                ? this.state.isShowGoogle
                                : "https:www.google.com/"
                            }
                            target="_blank"
                          >
                            <img
                              src={googleIcon}
                              className="social_logos w3-ripple"
                              alt="social"
                            />
                          </a>{" "}
                        </>
                      )}
                      {this.state.isShowInsta && (
                        <>
                         <a
                            href={
                              this.state.isShowInsta
                                ? this.state.isShowInsta
                                : "https:www.instagram.com/"
                            }
                            target="_blank"
                          >
                            <img
                              src={instaIcon}
                              className="social_logos w3-ripple"
                              alt="social"
                            />
                          </a>{" "}
                        </>
                      )}

                      {this.state.isShowTwitter && (
                        <>
                          <a
                            href={
                              this.state.isShowTwitter
                                ? this.state.isShowTwitter
                                : "https:twitter.com/"
                            }
                            target="_blank"
                          >
                            <img
                              src={twitterIcon}
                              className="social_logos w3-ripple"
                              alt="social"
                            />
                          </a>{" "}
                        </>
                      )}
                      {this.state.isShowYoutube && (
                        <>
                          <a
                            href={
                              this.state.isShowYoutube
                                ? this.state.isShowYoutube
                                : "https:youtube.com/"
                            }
                            target="_blank"
                          >
                            <AiOutlineYoutube className="youtube-style" />
                          </a>{" "}
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col className="col-md-3 col-sm-3 col text-center">
                  <div className="yt-footer-widget-title">Download App</div>
                  <div className="download-app-banner">
                    <img
                      className="p-image img-fluid"
                      onClick={() =>
                        window.open("https://play.google.com/store", "_blank")
                      }
                      alt="android"
                      src={googlePlayStoreImg}
                    />
                    <img
                      className="p-image img-fluid mt-2"
                      onClick={() =>
                        window.open(
                          "https://www.apple.com/in/app-store/",
                          "_blank"
                        )
                      }
                      alt="ios"
                      src={AppStoreImg}
                    />
                  </div>
                </Col>
              </Row>
            </>
          </div>
          <div className="bottombar">
            <>
              <p className="m-0 yt-copyright-text">
                {this.state.theamData?.footerContent?.copyright
                  ? this.state.theamData?.footerContent?.copyright
                  : content.copyRightText}
              </p>
            </>
          </div>
        </footer>
      </>
    );
  }
}

export default withRouter(React.memo(Footer));
