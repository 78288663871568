Object.defineProperty(exports, "__esModule", {
  value: true
});

/*** API Constants */
exports.ApiContentType = "application/json";
exports.EditApiContentType = "multipart/form-data";

/*** Methods List */
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";
exports.apiPutMethod = "PUT";
exports.apiDeleteMethod = "DELETE";
exports.apiPatchMethod = "PATCH";

/*** API's For Products */
exports.productAPiEndPoint = "restaurant/bx_block_catalogue/catalogues";
exports.brandAPiEndPoint = "restaurant/bx_block_catalogue/brands";
exports.cataloguesAPiEndPoint = "restaurant/bx_block_filter_items/filtering?";

/*** API's For Catalogue */
exports.getAllProductsAPIEndPoint="restaurant/bx_block_catalogue/catalogues";
exports.postWishlistAPIEndpoint ="restaurant/bx_block_wishlist/wishlists";
exports.delWishlistAPIEndpoint="restaurant/bx_block_wishlist/wishlists/remove_catalogue/";
exports.orderBlockAPIEndpoint = "restaurant/bx_block_order_management/orders";