import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { RouteComponentProps } from "react-router-dom";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { uuid } from "uuidv4";
import {
    apiCall,
    getLocalState,
    setLocalState,
  } from "../../../studio-store-restaurant-components/src/Utility.web";
  
const configJSON: any = require('./config');
// Customizable Area Start
import MessageConstants, {
    getMessageName
} from "../../../../blocks/studio-store-restaurant-components/src/MessageConstants";
// @ts-ignore
import { onMessageListener, getTokenData } from './firebaseInit.js';
// Customizable Area End

export interface Props extends RouteComponentProps {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    title: string;
    favicon: string;
    isAuthenticated: boolean
    invalidTokenMessageRecieved: boolean
    isTawkToPresent: boolean
    // Customizable Area End
}
interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


class AppController extends BlockComponent<Props, S, SS> {
    generateGuestTokenAPICallId: string = "";
    constructor(props: Props) {
        super(props);
        
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getMessageName(MessageConstants.reGenerateGuestToken),
            getMessageName(MessageConstants.updateFaviconAndTitle),
            // Customizable Area End
        ];
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        const {favicon, title} = JSON.parse(localStorage.getItem("favicon-title") ?? "{}");
        this.state = {
            title,
            favicon,
            isAuthenticated: false,
            invalidTokenMessageRecieved: false,
            isTawkToPresent: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        
        // Customizable Area End
    }

    logoutAndRedirectToLoginPage = () => {
        this.generateGuestToken();
        this.props?.history?.push({ pathname: '/login', state: { activeTab: "2", calledFrom: "guest-login" } });
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        Notification.requestPermission().then(function (permission) {  });
        getTokenData()
        this.initialize();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallID = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorMessage = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            

            if (this.parseExpireTokenResponse(responseJson, this.state, this.props)) {
               this.guestResponseCallBack(responseJson, apiRequestCallID);
            } else {
                this.setState({
                    invalidTokenMessageRecieved: true
                }, () => {
                    if (
                        Array.isArray(responseJson?.errors) &&
                        (responseJson?.errors.length > 0) &&
                        responseJson?.errors[0]?.token &&
                        (
                            responseJson?.errors[0]?.token == "Token has Expired" ||
                            responseJson?.errors[0]?.token == "Invalid token"
                        )
                    ) {
                        let user = getLocalState('user');
                        let token = getLocalState('token');
                        let guestUUID = getLocalState('guestUserID');
                        if (!user && (token && guestUUID)) {
                            this.generateGuestToken()
                        } else if (user) {
                            localStorage.clear();
                            this.logoutAndRedirectToLoginPage()
                        }
                    }
                })
            }
            // Customizable Area End
        }
        else if (getMessageName(MessageConstants.reGenerateGuestToken) === message.id) {
            this.generateGuestToken();
        }else if (getMessageName(MessageConstants.updateFaviconAndTitle) === message.id) {
            const messageData = message.getData(
                getMessageName(MessageConstants.updateFaviconAndTitleData)
            );
            this.setState({
                title: messageData?.title,
                favicon: messageData.favicon
            })
        } 
    }

    // Customizable Area Start
    isValidResponse = (responseJson:any) => {
        return responseJson && !responseJson.errors && responseJson.data;
    }

    guestResponseCallBack = (responseJson:any, apiRequestCallID:any) => {
        if (this.isValidResponse(responseJson)) {
            if (apiRequestCallID === this.generateGuestTokenAPICallId) {
                if (responseJson.meta) {
                    setLocalState(
                        "guestUserData",
                        JSON.stringify(responseJson.data)
                    );
                    setLocalState("token", responseJson.meta.token);
                    setLocalState("guestUserID", responseJson.data.attributes.uuid);
                    this.setState({
                        isAuthenticated: true,
                        invalidTokenMessageRecieved: false
                    });
                    window.location.href="./";
                }
            }
        }
    }
    initialize = () => {
        let token = localStorage.getItem("token");

        if (!token) {
            this.generateGuestToken()
        } else {
            this.setState({
                isAuthenticated: true
            })
        }
    }

    
    generateGuestToken = () => {
        this.setState({ isAuthenticated: false }, async () => {
            const generateID = uuid();
            const requestBody = {
                data: {
                    type: "guest_account",
                    attributes: {
                        uuid: generateID,
                    },
                },
            };
            localStorage.setItem("guestUserID", generateID);
            this.generateGuestTokenAPICallId = await apiCall({
                contentType: configJSON.ApiContentType,
                method: configJSON.guestLoginAPIMethod,
                endPoint: configJSON.guestLoginAPIEndPoint,
                body: requestBody,
            });
        })
    }
    // Customizable Area End
}
export default AppController
