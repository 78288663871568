import React from "react";
import { withRouter } from "react-router-dom";
// Customizable Area Start
import { Carousel } from "react-responsive-carousel";
import { BiChevronRightCircle, BiChevronLeftCircle } from "react-icons/bi";
import { withStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BannerSkeltonLoader from "../../studio-store-restaurant-components/src/SkeletonLoader/BannerSkeltonLoader";
import CategorySkeltonLoader from "../../studio-store-restaurant-components/src/SkeletonLoader/CategorySkeltonLoader";
import FilterOptions from "../../filteritems/src/FilterOptions.web";
import FilterProducts from "../../filteritems/src/Filterproducts.web";
import ShoppingCart from "../../shoppingcart/src/ShoppingCart.web";
import DashboardController from "./DashboardController.web";
import "../assets/css/index.scoped.css";
// Customizable Area End


const useStyles = {
  dashBoardCaraousel: {
    padding: "0 80px!important",
    "@media (max-width: 767px)":{
      padding: "0 24px!important",
    }
  },

  bannerSection:{
    paddingTop: "40px"
  },

  bannerImage:{
    height: "337px",
    borderRadius: "5px",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    "@media only screen and (min-device-width: 320px) and (max-device-width: 768px)":{
        height: "auto!important",
        borderRadius: "5px",
        backgroundPosition: "50%",
        backgroundSize: "cover",
    }
  },

  categorySkeleton:{
    "@media (min-width: 1366px) and (max-width: 1900px)":{
      padding: "40px 30px 35px !important"
    },
    "@media (max-width: 1279.92px)":{
      padding: "18px 15px 15px !important"
    },
    "@media (max-width: 767px)":{
      padding: "20px !important"
    }
  },

  arrowStyle:{
    position: "absolute" as "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: "45px",
    height: "45px",
    cursor: "pointer",
    left: "15px",
    fontSize: "20px",
    fill: "black",
  },

  filteredProducts:{
    zIndex: 0,
    "@media (min-width: 767.98px)":{
      position: "relative" as "relative"
    }
  }
}

class Dashboard extends DashboardController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    let myCategory = { myCategory: this.state.categoryList };
    const { classes } = this.props;
    return (
      <>
        <section className={classes.dashBoardCaraousel}>
          <div className={classes.bannerSection}>
            {this.state.bannerImgList?.length > 0 ? (
              <Carousel
                showStatus={false}
                showArrows={false}
                swipeable={true}
                autoPlay={true}
                showIndicators={
                  this.state.bannerImgList.length > 1 ? true : false
                }
                interval={5000}
                infiniteLoop={true}
                className="carousel"
                showThumbs={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <BiChevronLeftCircle
                      onClick={onClickHandler}
                      className={classes.arrowStyle}
                    />
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <BiChevronRightCircle
                      onClick={onClickHandler}
                      className={classes.arrowStyle}
                    />
                  )
                }
              >
                {this.state.bannerImgList.map((banner, index: number) => (
                  <div
                    key={index}
                    onClick={() => {
                      banner?.url_link &&
                        window.location.replace(String(banner.url_link));
                    }}
                    data-testid="bannerClick"
                  >
                    <img
                      src={banner.url}
                      style={
                        banner.url_link
                          ? { cursor: "pointer" }
                          : { cursor: "default" }
                      }
                      className={classes.bannerImage}
                    />
                  </div>
                ))}
              </Carousel>
            ) : (
              <BannerSkeltonLoader />
            )}
          </div>
          {this.state.categoryList?.length ? (
            <FilterOptions {...myCategory} />
          ) : (
            <div className={classes.categorySkeleton}>
              <CategorySkeltonLoader />
            </div>
          )}
          <>
            <div className="h-100" id="sticky-container">
              <div className="row">
                <div className="col-md-7 col-sm-7 col">
                  <div className={classes.filteredProducts}>
                    <FilterProducts
                      myCategory={this.state.categoryList}
                      getProducts={this.getProductList}
                      getCarts={this.getCartList}
                      updateProductsQuantity={this.productsQuantity}
                      myLoader={this.state.loader}
                      myProductsList={this.state.productList}
                    />
                  </div>
                </div>
                <div className="col-md-5 col-sm-5 col">
                  <div className={classes.filteredProducts}>
                    <ShoppingCart
                      getProducts={this.getProductList}
                      getCarts={this.getCartList}
                      myCartList={this.state.cartList}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        </section>
      </>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
export default withRouter(withStyles(useStyles)(Dashboard));
export { Dashboard };
// Customizable Area Start
// Customizable Area End
