import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import ShoppingCartController, {
  Props,
  configJSON,
} from "./ShoppingCartController";

// Customizable Area Start
import { arrowLeft, NoProduct } from "./assets";
// import "../assets/css/emptyCart.css";
import CartSkeltonLoader from "../../studio-store-restaurant-components/src/SkeletonLoader/CartSkeltonLoader";
import EmptyCartContentController from "./EmptyCartContentController.web";
// Customizable Area End


const useStyles = {

  ytEmptyCart: {
    height: '557px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center" as "center",
  },

  profilePgOrderMainWrap: {
    padding: '145px 0',
    '@media (max-width: 767.92px) ': {
      padding: 0,
    },
    '@media (max-width: 1279.92px)': {
      padding: '40px 0',
    },
    '@media (max-width: 1900px) and (min-width: 1366px)': {
      padding: '145px 0',
    }
  },

  imgEmptybasket: {
    background: '#f5f5f5',
    height: '188px',
    width: '188px',
    padding: '47px',
    borderRadius: '100%',
    margin: 'auto',
    '@media (min-width: 768px)': {
      height: "150px",
      width: "150px",
    }
  },

  ppSaOrderWrap: {
    '@media (max-width: 1279.92px)': {
      marginBottom: '30px !important'
    }
  },

  emptyCartText: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: "center" as "center",
    color: '#000000',
    '@media (max-width: 575.98px)': {
      maxWidth: '233px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '@media (max-width: 767.92px)': {
      fontSize: "15px",
      lineHeight: "1.2"
    },
    '@media (max-width: 1279.92px)': {
      fontSize: "14px",
      lineHeight: "1.14"
    }
  },

}


class EmptyCartContent extends EmptyCartContentController {

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <div>
        <div className={`${classes.ytEmptyCart} bg-white radius-10 mb-4 m-auto p-3`}>
          <div className={`${classes.profilePgOrderMainWrap} text-center `}>
            {this.state.isShowingSkeltonLoader ? (
              <CartSkeltonLoader />
            ) : (
              <>
                <div className={`${classes.imgEmptybasket} mb-5 `}>
                  <img src={NoProduct} className="img-fluid" />
                </div>
                <div className={`${classes.ppSaOrderWrap} mb-5 mt-2`}>
                  <h2 className={`${classes.emptyCartText} mt-0 mb-3`}>Empty basket</h2>
                  <p className="pp-od-no-text mb-0">
                    <img
                      src={arrowLeft}
                      alt="arrowLeft"
                      height={19}
                      width={15}
                      className="img-fluid "
                    />{" "}
                    <span className="p-1">
                      Start adding items to your order
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

export default withRouter(withStyles(useStyles)(EmptyCartContent));
export { EmptyCartContent };
// Customizable Area Start
// Customizable Area End
