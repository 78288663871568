Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
/*** API Constants */
exports.ApiContentType = "application/json";
exports.EditApiContentType = "multipart/form-data";

/*** Methods List */
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";
exports.apiPutMethod = "PUT";
exports.apiDeleteMethod = "DELETE";
exports.apiPatchMethod = "PATCH";

/*** API's for Cart */
exports.getCartDetailsAPIEndPoint = "restaurant/bx_block_order_management/get_cart";
exports.showCartDetailsAPIEndPoint = "restaurant/bx_block_order_management/orders";
exports.updateCartAPIEndPoint = "restaurant/bx_block_order_management/orders";
exports.getCartCountAPIEndPoint = "restaurant/bx_block_order_management/orders/has_product";
exports.getCouponsListAPIEndPoint = "restaurant/bx_block_coupon_cg/coupon_code_generator";
exports.applyCouponAPIEndPoint = "restaurant/bx_block_order_management/orders";
exports.removeCouponAPIEndPoint = "restaurant/bx_block_order_management/orders";
exports.applyRewardsAPIEndPoint = "restaurant/bx_block_loyaltysystem/rewards";
exports.removeRewardsAPIEndPoint = "restaurant/bx_block_loyaltysystem/rewards";
exports.getWebBannersAPIEndpoint = "restaurant/bx_block_banner/banners/web_banners_list"

/*** API's for Common Settings */
exports.getCommonSettingsAPIEndPoint = "restaurant/bx_block_settings/common_settings";

/*** API's for Block Order */
exports.orderBlockAPIEndpoint = "restaurant/bx_block_order_management/orders";
// Customizable Area End