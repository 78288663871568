import React from "react";
import { withRouter } from "react-router-dom";
import FilterOptionsListController from "./FilterOptionsListController.web";
import { withStyles } from '@material-ui/core/styles';
// Customizable Area Start
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { Button } from "reactstrap";
import { ItemsCategoryType } from "../../studio-store-restaurant-utilities/src/types/CommonTypes";

const useStyles = {
  mainWrapper: {
    textAlign: "center" as "center",
    margin: "auto",
    marginTop:"3rem",
    marginBottom:"3rem"
  },

  pillsButton:{
    height: "auto",
    justifyContent: "center",
    display: "inline-flex",
    "@media only screen and (min-device-width: 320px) and (max-device-width: 768px)":{
      width: "100% !important"
    }
  },

  categoryTabs:{
    listStyle: "none",
    padding: "0",
    margin:"0",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    "& .category-pills-btn":{
      background: "transparent !important",
      fontWeight: "400 !important",
      fontSize: "18px !important",
      color: "#000 !important",
      border: "none !important",
      minWidth: "max-content",
      borderRadius: "20px !important",
      padding: "9px 24px !important",
      marginRight: "2.5rem",
      "&:hover":{
        color:" #000!important",
        background: "linear-gradient(0deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,.8)),#26d8e4!important",
      }
    },
    "& .active":{
      background: "#3fc1cb!important",
      fontWeight: "500 !important",
      color: "#fff!important",
      "@media only screen and (min-device-width: 320px) and (max-device-width: 768px)":{
        fontSize: "14px!important"
      }
    }
  },

  moreButton:{
    padding: "7px 25px",
    cursor: "pointer",
    "& svg":{
      height: "1.4rem",
      width: "1.4rem",
    }
  },

  moreCategoryWrapper:{
    position: "relative" as "relative",
    "& .more-btn-none":{
      display: "none"
    },
    "& .more-btn-inline":{
      maxHeight: "325px",
      overflowY: "scroll",
      display: "block",
      position: "absolute",
      zIndex: 9,
      background: "#fff",
      border: "1px solid #dfe0e6",
      boxSizing: "border-box",
      boxShadow: "0 0 32px #ebebeb",
      borderRadius: "10px",
      top: "40px",
      left: "50%",
      transform: "translate(-80%)",
      "&::-webkit-scrollbar-track":{
        background: "#f1f1f1",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb":{
        background: "#888",
        borderRadius: "10px"
      },
      "&::-webkit-scrollbar":{
        width:"5px"
      },
      "@media (max-width: 1440px)":{
        display: "block",
        position: "absolute",
        zIndex: 9,
        background: "#fff",
        padding: "5px",
        boxShadow: "0 8px 16px 0 rgb(0 0 0 / 20%)",
        cursor: "pointer",
        right: "auto",
        left: "auto",
        transform: "translate(-80%)",
      },
      "@media (max-width: 1279.92px)":{
        display: "block",
        position: "absolute",
        zIndex: 9,
        background: "#fff",
        padding: "5px",
        boxShadow: "0 8px 16px 0 rgb(0 0 0 / 20%)",
        cursor: "pointer",
        right: "auto",
        transform: "translate(-80%)",
      },
      "@media (max-width: 767.92px)":{
        display: "block",
        position: "absolute",
        zIndex: 9,
        background: "#fff",
        padding: "5px",
        boxShadow: "0 8px 16px 0 rgb(0 0 0 / 20%)",
        cursor: "pointer",
        right: "auto",
        transform: "translate(-95%)",
      }
    },
    "& .border-bottom":{
      border: "1px solid #dfdfdf"
    },
    "& .category-pills-btn":{
      background: "transparent !important",
      fontWeight: "400 !important",
      fontSize: "18px !important",
      color: "#000 !important",
      border: "none !important",
      minWidth: "max-content",
      borderRadius: "20px !important",
      padding: "9px 24px !important",
      marginRight: "2.5rem",
      "&:hover":{
        color:" #000!important",
        background: "linear-gradient(0deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,.8)),#26d8e4!important",
      }
    },
    "& .active":{
      background: "#3fc1cb!important",
      fontWeight: "500 !important",
      color: "#fff!important",
      "@media only screen and (min-device-width: 320px) and (max-device-width: 768px)":{
        fontSize: "14px!important"
      }
    }
  }
}
// Customizable Area End

class FilterOptions extends FilterOptionsListController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    let itemsCategory = this.props.myCategory;
    if (this.state.buttonsToAllow) {
      itemsCategory = this.props.myCategory?.slice(
        0,
        this.state.buttonsToAllow
      );
    }
    let itemsMoreCategory: ItemsCategoryType[] = [];
    if (this.state.shouldAddMore)
      itemsMoreCategory = (this.props.myCategory ?? [])?.slice(
        this.state.buttonsToAllow,
        this.props.myCategory?.length
      );

    return (
      <div className={classes.mainWrapper} ref={this.wrapperRef}>
        <div className={classes.pillsButton}>
          <div>
            <div className={classes.categoryTabs}>
              {itemsCategory &&
                itemsCategory?.map((category, index: number) => (
                  <Button
                    ref={this.buttonRef}
                    key={category?.id}
                    data-testid="categoryButton"
                    onClick={() => this.routeToFilter(category?.id, index)}
                    className={
                      this.state.activeCategoryId === category?.id
                        ? "category-pills-btn active"
                        : "category-pills-btn"
                    }
                  >
                    {category.attributes.name}
                  </Button>
                ))}
            </div>
          </div>
          {this.state.shouldAddMore && (
            <>
              <div
                className={classes.moreButton}
                onClick={() => this.setState({ more: !this.state.more })}
                data-testid="moreItemListID"
              >
                <span>
                  More &nbsp;
                  {this.state.more ? (
                    <FaCaretUp color="#3fc1cb"/>
                  ) : (
                    <FaCaretDown/>
                  )}
                </span>
              </div>
              <div className={classes.moreCategoryWrapper}>
                <div
                  className={
                    this.state.more ? "more-btn-inline" : "more-btn-none"
                  }
                >
                  <div style={{ display: "grid" }}>
                    {itemsMoreCategory &&
                      itemsMoreCategory?.map((category, index: number) => (
                        <div key={index}>
                          <Button
                            key={category?.id}
                            onClick={() =>
                              this.routeToFilter(category?.id, index)
                            }
                            className={
                              this.state.activeCategoryId === category?.id
                                ? "category-pills-btn m-2 active"
                                : "category-pills-btn m-2"
                            }
                          >
                            {category.attributes.name}
                          </Button>
                          <span className="border-bottom"></span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
  // Customizable Area End
}

export default withRouter(withStyles(useStyles)(FilterOptions));
export { FilterOptions };
// Customizable Area Start
// Customizable Area End
