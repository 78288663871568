//@ts-nocheck
import React, { PureComponent } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";

export type Props = RouteProps & {};

interface S {
  token: string;
  loading: boolean;
  guestdata?: string;
  isLoggedUser?: boolean;
}

export default class PrivateRoute extends PureComponent<Props, S> {
  constructor() {
    super();
    this.state = {
      token: "",
      loading: true,
      guestdata: "",
      isLoggedUser: false,
    };
  }
  async componentDidMount() {
    let tokenn = localStorage.getItem("token");
    let GuestUserId = localStorage.getItem("guestUserID");
    let userInfo = localStorage.getItem("userData");
    if (GuestUserId && tokenn) {
      this.setState({
        guestdata: GuestUserId,
        token: tokenn,
        loading: false,
        isLoggedUser: true,
      });
    }
    if (tokenn && userInfo) {
      this.setState({
        loading: false,
        token: tokenn,
        isLoggedUser: true,
      });
    }
    if (tokenn) {
      this.setState({
        token: tokenn,
        loading: false,
        isLoggedUser: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }
  render() {
    return !this.state.loading ? (
      this.state.token == "" ? (
        this.state.token == "" &&
        this.state.guestdata &&
        this.state.isLoggedUser ? (
          <Redirect to="/" />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      ) : (
        <Route {...this.props} />
      )
    ) : (
      <></>
    );
  }
}
