import React, { Component } from "react";
import { closeIcon, noSearchIcon } from "./assets";

import "./css/search.web.css";
type MyProps = {
  isMobile: boolean;
  hideSearch: () => void;
  children: any;
};

export class NotsearchFound extends Component<MyProps> {
  render() {
    return (
      <>
        <div className="product-square" />
        <div className="yt-recent-search-wrap my-2">
          {this.props.isMobile && this.props.children}
          <div
            className="d-flex align-items-center justify-content-end"
            onClick={() => this.props.hideSearch()}
          >
            <img alt="#img" src={closeIcon} />
          </div>
          <div className="text-center yt-no-result mb-2">
            <img alt="#img" src={noSearchIcon} />
            <h2 className="search-no-ttl">No Results Found</h2>
            <h4 className="search-no-sub-ttl my-0">
              Try modifying your search to get relevant results.
            </h4>
          </div>
        </div>
      </>
    );
  }
}

export default NotsearchFound;
