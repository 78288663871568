//play store Images
export const googlePlayStoreImg = require('./images/GooglePlayStore.svg');
export const AppStoreImg = require('./images/AppStore.svg');

//Social Media Icons
export const twitterIcon = require('./images/twitter-social.svg');
export const googleIcon = require('./images/google.png');
export const instaIcon = require('./images/social-instagram.svg');
export const facebookIcon = require('./images/Footer-Fb.svg');
export const youtubeIcon = require('./images/YOutube.png');

//loader
export const loaderIcon = require('./images/animated_spinner.webp')

//Promotions
export const promotionsImg = require('./images/promotions.png');

//logo 
export const logoImg = require('./images/logo.svg');
