import React from "react";
import Skeleton from 'react-loading-skeleton';

const AdvBannerSkeltonLoader = () => (
  <>
    <div className="row">
      <Skeleton containerClassName="skeleton-adv-bg"  />
    </div>
  </>
);


export default AdvBannerSkeltonLoader;
