import { IBlock } from "../../../framework/src/IBlock";
import { RouteComponentProps } from "react-router-dom";

import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Starts
  classes: Record<string, string>;
  // Customizable Area End
}

export interface Props {
  // Customizable Area Start
  // Customizable Area End
}
export interface S {
  // Customizable Area Start
  isShowingSkeltonLoader: boolean
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EmptyCartContentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.state = {
      isShowingSkeltonLoader: true,
    }
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    setTimeout(() => {
      this.setState({ isShowingSkeltonLoader: false });
    }, 2000);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}
