import React, { Fragment } from "react";
import ExistSearch from "./ExitSearch";
import NotsearchFound from "./NotsearchFound";

export default function SearchData(props: any) {
  let filtered = [];
  if (Array.isArray(props.results)) {
    filtered = props.results;
  }
  return (
    <Fragment>
      <span>
        {filtered.length > 0 ? (
          <ExistSearch {...props} results={filtered} />
        ) : (
          <NotsearchFound {...props} />
        )}
      </span>
      <div
        className="w3-overlay w3-show"
        style={{ zIndex: 49, backgroundColor: "transparent" }}
        onClick={props.hideSearch}
      />
    </Fragment>
  );
}
