// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from '../../blocks/studio-store-restaurant-router/src/web/App';

import registerServiceWorker from '../../components/src/registerServiceWorker';
//css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

//Notifications Toaster File
//@ts-ignore
import NotificationToaster from './notificationToaster.js';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then(registration => {
      
    })
    .catch(err => {
    });
}

ReactDOM.render(
  <Router>
    <div className="">
      <App />
      <NotificationToaster />
    </div>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
