//@ts-nocheck
import React, { PureComponent } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

export type Props = RouteProps & {};

interface S {
  token: string;
  loading: boolean;
  guestdata?: string;
}

export default class ProtectedRoute extends PureComponent<Props, S> {
  constructor() {
    super();
    this.state = {
      token: '',
      loading: true
    };
  }
  async componentDidMount() {
    let tokenn = await localStorage.getItem('token');
    let GuestUserId = await localStorage.getItem('guestUserID');
    if (GuestUserId && tokenn) {
      this.setState({
        guestdata: GuestUserId,
        token: tokenn,
        loading: false
      });
    }
    if (tokenn) {
      this.setState({
        token: tokenn,
        loading: false
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }
  render() {
    return !this.state.loading ? (
      this.state.token == '' ? (
        this.state.token == '' && this.state.guestdata ? (
          <Redirect to={{ pathname: '/login' }} />
        ) : (
          <Redirect to="/" />
        )
      ) : (
        <Route {...this.props} />
      )
    ) : null;
  }
}
